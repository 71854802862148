import React from "react";
import PropTypes from "prop-types";
import { PageTitle } from "./PageHeading.styled";
import { HR } from "../HR.styled";
import { Heading3 } from "../Typography/Typography.styled";

const PageHeading = ({ title, subtitle }) => {
  return (
    <>
      <PageTitle>{title}</PageTitle>
      <Heading3 as="p" center>
        {subtitle}
      </Heading3>
      <HR></HR>
    </>
  );
};

PageHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default PageHeading;
