import styled from "styled-components";

export const PageTitle = styled.h1`
  margin-top: 4.9rem;
  margin-bottom: 0.5rem;
  font-family: "Open Sans", sans serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 219%;
  text-align: center;
  text-transform: uppercase;

  color: #aeaeae;
`;
