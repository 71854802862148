import React from "react";
import { Img } from "../UI/Img.styled";
import { ServicesPageContainer } from "./ServicesPage.styled";

const ServicesPage = ({ pageTitle, subtitle, imgLink, imgAlt, body }) => {
  return (
    <ServicesPageContainer
      title={pageTitle}
      subtitle={subtitle}
      textContainer
      secondary
    >
      <Img src={imgLink} alt={imgAlt} center></Img>
      <section dangerouslySetInnerHTML={{ __html: body }}></section>
    </ServicesPageContainer>
  );
};

export default ServicesPage;
