import styled from "styled-components";
import PageContainer from "../Layout/PageContainer/PageContainer";
import {
  SmallHeadingMixin,
  TextMixin,
} from "../UI/Typography/Typography.styled";

export const ServicesPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  section h2 {
    ${SmallHeadingMixin};
    text-transform: capitalize;
    max-width: 50ch;
  }
  section p {
    ${TextMixin};
  }
  section ul {
    ${TextMixin};
    list-style-type: disc;
    margin-left: 2rem;
    li {
      padding-left: 1rem;
    }
  }
`;
