import React from "react";
import PropTypes from "prop-types";
import PageHeading from "../../UI/PageHeading/PageHeading";
import { Container } from "../LayoutElements.styled";

const PageContainer = ({
  title,
  subtitle,
  children,
  textContainer,
  className,
}) => {
  return (
    <Container
      className={className}
      as="article"
      marginBottom
      center
      textContainer={textContainer}
    >
      <PageHeading title={title} subtitle={subtitle}></PageHeading>
      {children}
    </Container>
  );
};

PageContainer.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  textContainer: PropTypes.bool,
};

export default PageContainer;
